/* eslint-disable no-unreachable */
import * as types from "./types";
import Vue from "vue";
import router from "@/router/index.js";
// context === {dispatch: ƒ, commit: ƒ, getters: {…}, state: {…}, rootGetters: {…},…}
export default {
  async [types.SET_USER]({ commit, dispatch }, user) {
    await commit(types.SET_USER, user);
    if (!user) {
      commit(types.SET_FIREBASE_LOADED);
      return null;
    }
    try {
      // await dispatch(user.VISIT_USER);
    } catch (e) {
      console.log(e.message);
    }
    await dispatch(types.SET_TOKEN);
    return true;
  },

  async [types.VISIT_USER]({ state }) {
    if (!state.user.displayName) return false;
    const increment = Vue.prototype.$firebase.firestore.FieldValue.increment(1);
    await Vue.prototype.$firebase
      .firestore()
      .collection("users")
      .doc(state.user.uid)
      .update({
        displayName: state.user.displayName,
        visitedAt: new Date(),
        visitCount: increment,
      });
    return true;
  },

  async [types.SET_TOKEN]({ commit, state }) {
    const timer = await setInterval(async () => {
      const token = await state.data.user.getIdToken(true);
      if (token) {
        commit(types.SET_TOKEN, token);
        const { claims } = await state.data.user.getIdTokenResult();
        await commit(types.SET_CLAIMS, claims);
        commit(types.SET_FIREBASE_LOADED);
        clearInterval(timer);
        return true;
      }
    }, 500);
  },

  async [types.SET_LOGOUT]({ commit, dispatch }, route) {
    dispatch(types.SET_USER, null);
    commit(types.SET_CLAIMS, null);
    commit(types.SET_TOKEN, null);
    await Vue.prototype.$firebase.auth().signOut();
    if (route) {
      router.push({ name: route });
    }
    return true;
  },
};
