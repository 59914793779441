/* eslint-disable no-unused-vars */
import Vue from "vue";
import axios from "axios";
import store from "../store";
import router from "../router";

const firebaseAPI = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://asia-northeast3-madeall.cloudfunctions.net/"
      : "http://localhost:5001/madeall/asia-northeast3/",
  timeout: 50000,
  headers: { "X-Custom-Header": "foobar" },
});

firebaseAPI.interceptors.request.use(
  async (config) => {
    config.headers.authorization = store.getters["auth/user/GET_TOKEN"];
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

firebaseAPI.interceptors.response.use(
  function (response) {
    // 200대 response를 받아 응답 데이터를 가공하는 작업
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    console.error(`Error: ${error.response.data.code}`);
    const originalRequest = config;
    if (status === 401) {
      switch (error.response.data.code) {
        case "auth/id-token-expired":
          if (store.getters["auth/user/GET_USER_CLAIM"].level < 3) {
            await store.dispatch(
              "auth/user/SET_USER",
              Vue.prototype.$firebase.auth().currentUser,
            );
            originalRequest.headers.authorization = await store.getters[
              "auth/user/GET_TOKEN"
            ];
            return axios(originalRequest);
          } else {
            await Vue.prototype.$firebase.auth().signOut();
            localStorage.clear();
            return;
          }
      }
    }

    return Promise.reject(error);
  },
);

Vue.prototype.$axios = firebaseAPI;
