/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const pageLogWrite = (to) => {
  const { uid, email } = store.getters["auth/user/GET_USER"];
  // Vue.prototype.$firebase
  //   .firestore()
  //   .collection("pageLogs")
  //   .add({
  //     uid,
  //     email,
  //     to: to.path,
  //     createdAt: new Date(),
  //   })
  //   .catch((e) => console.log(e.message));
};

const levelCheck = (level) => {
  return (to, from, next) => {
    if (!store.getters["auth/user/GET_USER"]) {
      if (level < 3) {
        return next("/404");
      }
      if (to.path !== "/login") {
        if (to.path !== "/") {
          Vue.prototype.$toasted.global.error("로그인이 필요합니다.");
        }
        return next("/login");
      }
    } else {
      if (store.getters["auth/user/GET_CLAIMS"] === null)
        return next("/mypage");
      if (store.getters["auth/user/GET_CLAIMS"].level > level)
        return next("/404");
      pageLogWrite(to);
    }
    next();
  };
};
const routes = [
  {
    path: "/",
    redirect: "page",
  },
  {
    path: "/page",
    component: () => import("@/views/Page/Index.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Page/Home/Home.vue"),
      },
      {
        path: "about",
        name: "AboutUs",
        component: () => import("@/views/Page/AboutUs/AboutUs.vue"),
      },
      {
        path: "service",
        name: "Service",
        component: () => import("@/views/Page/Service/Service.vue"),
      },
      {
        path: "order",
        name: "Order",
        component: () => import("@/views/Page/Order/Order.vue"),
      },
      {
        path: "products",
        name: "Products",
        component: () => import("@/views/Page/Products/Products.vue"),
      },
      {
        path: "portfolio",
        name: "Portfolio",
        component: () => import("@/views/Page/Portfolio/Portfolio.vue"),
      },
      {
        path: "portfolio/:id",
        component: () => import("@/views/Page/Portfolio/Portfolio.vue"),
      },
      {
        path: "press",
        name: "Press",
        component: () => import("@/views/Page/Press/Press.vue"),
      },
    ],
  },
  {
    path: "/mypage",
    name: "MyPage",
    beforeEnter: levelCheck(3),
    component: () => import("@/views/Page/MyPage/Index.vue"),
    // children: [
    //   {
    //     path: "",
    //     name: "MyPage-UserInfo",
    //     component: () => import("@/views/Page/MyPage/UserInfo.vue"),
    //   },
    // ],
  },
  {
    path: "/admin",
    beforeEnter: levelCheck(2),
    component: () => import("@/views/Admin/Index.vue"),
    children: [
      {
        path: "",
        name: "Admin-Dashboard",
        component: () => import("@/views/Admin/AdminDashboard.vue"),
      },
      {
        path: "users",
        name: "Admin-Users",
        component: () => import("@/views/Admin/AdminUsers.vue"),
      },
      {
        path: "orders",
        name: "Admin-Orders",
        component: () => import("@/views/Admin/AdminOrders.vue"),
      },
      {
        path: "calendar",
        name: "Admin-Calendar",
        component: () => import("@/views/Admin/AdminCalendar.vue"),
      },
      {
        path: "portfolio",
        name: "Admin-Portfolio",
        component: () => import("@/views/Admin/AdminPortfolio.vue"),
      },
      {
        path: "press",
        name: "Admin-Press",
        component: () => import("@/views/Admin/AdminPress.vue"),
      },
      {
        path: "devtest",
        name: "Dev-Test",
        component: () => import("@/views/Admin/DevTest.vue"),
      },
    ],
  },

  /**
   * AUTHORIZATION
   */
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Authorization/LoginPage.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/user/GET_USER"]) {
        next();
      } else {
        if (store.getters["auth/user/GET_CLAIMS"].disabled) {
          next("/signup");
        } else {
          Vue.prototype.$toasted.global.error("이미 로그인 되었습니다.");
          next("/page");
        }
      }
    },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/Authorization/SignUpPage.vue"),
  },

  {
    path: "/callback",
    name: "callback",
    redirect: "/login",
    component: () => import("../views/Authorization/callback/index.vue"),
    children: [
      {
        path: "kakaotalk",
        name: "callback-kakaotalk",
        component: () => import("@/views/Authorization/callback/KakaoTalk.vue"),
      },
      {
        path: "naver",
        name: "callback-naver",
        component: () => import("@/views/Authorization/callback/Naver.vue"),
      },
    ],
  },

  {
    path: "/404",
    name: "404",
    component: () => import("../views/Page/404.vue"),
  },

  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const waitFirebase = () => {
  return new Promise((resolve, reject) => {
    let cnt = 0;
    const timer = setInterval(() => {
      if (store.getters["auth/user/GET_FIREBASE_LOADED"]) {
        clearInterval(timer);
        resolve();
      } else if (cnt++ > 500) {
        clearInterval(timer);
        reject(Error("서버 연결을 실패했습니다."));
      }
    }, 10);
  });
};

router.beforeEach(async (to, from, next) => {
  waitFirebase()
    .then(async () => {
      // 사용자 토근 유효성 검사하면 되는 섹션
      // 회원가입 완료 여부 체크
      if (
        to.path == "/signup" ||
        to.path == "/login" ||
        to.path == "/callback/kakaotalk" ||
        to.path == "/callback/naver" ||
        from.path == "/signup"
      ) {
        next();
      } else {
        if (
          store.getters["auth/user/GET_CLAIMS"] &&
          store.getters["auth/user/GET_CLAIMS"].disabled == true
        ) {
          Vue.prototype.$toasted.global.error(
            "회원가입이 완료되지 않았거나 비활성화 된 계정입니다.",
          );
          next("/signup");
        } else {
          next();
        }
      }
      // next();

      // 사용자 토큰 갱신
      const user = Vue.prototype.$firebase.auth().currentUser;
      if (user) {
        await store.dispatch("auth/user/SET_USER", user);
      }
    })
    .catch((e) => Vue.prototype.$toasted.global.error(e.message));
});

router.afterEach((to, from) => {
  // Vue.prototype.$Progress.finish();
});
// // Router Error 처리
router.onError((e) => {
  Vue.prototype.$toasted.global.error(e.message);
});
export default router;
